<template>
	<section-ex-cource-detail :id="String(id)" ></section-ex-cource-detail>
 </template>

<script>
import SectionExCourceDetail from "@/components/SectionExCourceDetail";

export default {
  components: {
		SectionExCourceDetail
  },
	props: ["id"]
};
</script>