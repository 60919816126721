<template>
  <div class="-trend">
    <div class="c_main -pad-btm">
      <div class="inner">
        <nav class="c_pnkz">
          <ul class="c_pnkz_list">
            <li>
              <a class="c_pointer" @click="$router.push({ name: 'Home' })">ホーム</a>
            </li>
            <li>
              <a class="c_pointer" @click="gotoReviewExLearning" >外部教育口コミ</a>
            </li>
            <li>{{ contents.name }}</li>
          </ul>
        </nav>
        <p class="c_main_ttl">{{ contents.company }} - {{ contents.name }}</p>

        <div class="c_result_list">
          <h2 class="chat_ttl">あなたの口コミ</h2>
          <div v-if="existsMyReview && !isEdit" class="c_tool_item">
            <div class="c_tool_item_cnt">
              <p class="c_tool_item_disc">{{ contents.my_review.comment }}</p>
              <div class="c_tool_item_eval">
                <div class="c_tool_item_eval mr-1">
                  <div class="c_tool_item_eval_star">
                    <star-rating :value="contents.my_review.rating" :width="13" :height="13"></star-rating>
                  </div>
                </div>
              </div>
              <p class="c_tool_item_info">
                <span><time>{{
                  contents.my_review.updated_at | formatDateDot
                }}</time></span>
              </p>
            </div>
            <div class="c_tool_item_link">
              <a class="c_tool_item_link" @click="clickMyReview">編集する</a>
            </div>

          </div>
          <section v-else class="chat">
            <div class="chat_form">
              <star-rating v-model="form.rating" class="rating" :width="20" :height="20"></star-rating>
              <textarea class="chat_form_msg" placeholder="メッセージを入力する" v-model="form.comment"></textarea>
              <div class="chat_form_btn">
                <button class="c_btn chat_form_btn_submit" type="button" @click="send">
                  <img src="/img/mypage/img-submit@2x.png" width="15" height="15" alt /> 投稿する
                </button>
              </div>
            </div>
          </section>
        </div>

        <!-- Review一覧 -->
        <div class="c_result_list">
          <h2 class="chat_ttl">みんなの口コミ</h2>
          <div class="c_tool_item" v-for="review in contents.other_reviews" :key="review.id">
            <div class="c_tool_item_cnt">
              <p class="c_tool_item_disc">{{ review.comment }}</p>
              <div class="c_tool_item_eval">
                <div class="c_tool_item_eval mr-1">
                  <div class="c_tool_item_eval_star">
                    <star-rating :value="review.rating" :width="13" :height="13"></star-rating>
                  </div>
                </div>
              </div>
              <p class="c_tool_item_info">
                <span><time>{{
                  review.updated_at | formatDateDot
                }}</time></span>
              </p>
            </div>
          </div>

          <div :class="{ c_load: loading }"></div>
          <div class="c_pageback">
            <a class="c_txtlink" @click="gotoReviewExLearning">一覧に戻る</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "@/components/StarRating";

export default {
  components: {
    StarRating,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      accessToken: "",
      contents: {
        my_review: {},
        other_reviews: []
      },
      form: {
        comment: "",
        rating: 3
      },
      loading: false,
      isEdit: false,
    };
  },
  computed: {
    existsMyReview: function () {
      if (Object.keys(this.contents.my_review).length === 0) {
        return false
      }
      return true
    }
  },
  created() {
    this.$auth.getTokenSilently().then((token) => {
      this.accessToken = token;
    });
    this.fetch();
  },
  watch: {
    '$route': 'fetch'
  },
  methods: {
    fetch() {
      this.isEdit = false;
      this.loading = true;
      this.$authAxios
        .get(`/api/review/ex-learning/courses/${this.id}`)
        .then((response) => {
          this.contents = response.data;
          if (Object.keys(this.contents.my_review).length !== 0) {
            this.form.comment = this.contents.my_review.comment;
            this.form.rating = this.contents.my_review.rating;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    send() {
      this.loading = true;
      let request;
      if(this.isEdit) { // 更新
        request = this.$authAxios.put(`/api/review/ex-learning/review/${this.contents.my_review.id}`, this.form);
      } else {
        request = this.$authAxios.post(`/api/review/ex-learning/courses/${this.id}`, this.form);
      }
      request.then((response) => {
        this.contents = response.data;
        this.form = {comment:"", rating:3};
        this.fetch();
      })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clickMyReview() {
      this.isEdit = true;
    },
    gotoReviewExLearning() {
      this.$router.push({ name: 'ReviewExLearning', query: this.$store.getters.getPrevSearchQuery() })
    }
  },
};
</script>

<style scoped>
@import "~@/css/mypage.css";
</style>

<style scoped>
.c_result_list {
  margin-top: 12px;
}

.c_btn.chat_form_btn_submit {
  padding: 0;
}

.chat {
  margin: 0;
  padding: 8px 30px;
}

.chat .chat_form {
  margin-top: 8px;
}

.chat_form .rating {
  margin-bottom: 8px;
}

h2.chat_ttl {
  margin-top: 16px;
}
</style>
